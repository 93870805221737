
.exam-wrapper{min-height:100vh; background-color:#fff;}

.exam-header{background-color:#DD0000; padding:10px 0}
.exam-header h2{color:#fff; font-weight:bold; margin:0}
.exam-header h2 span{font-weight:normal;}
.exam-header-container{padding-left:40px; padding-right:40px; display:flex; justify-content:space-between; align-items:center;}

.exam-action-bar{background-color:#F4F4F4; padding:10px 0}
.exam-action-bar-block{padding-left:40px; padding-right:40px; display:flex; justify-content:space-between; align-items:center;}

.exam-header-container .time{color:#fff; display:flex; justify-content:flex-end; align-items:baseline;}
.exam-header-container .time span{font-size:15px;}
.exam-header-container .time strong{font-size:20px; margin-left:10px; font-weight:normal}

.countdown-timer{background-color:#850303; padding:10px 20px; border-radius:30px; width:75px; display:flex; align-items:center; justify-content:center;}
.exam-header-container .time .countdown-timer span{font-weight:bold; font-size:16px}

.exam-body{display:flex; background-color:#fff; height:100%;}

.intro-screen{background-image:url(../graphics/exam-bg.png); background-repeat:no-repeat; background-position:center 400px;}

.exam-main{width:70%; min-height:600px; height:100vh; overflow-y:auto;}
.exam-sidebar{background-color:rgba(255, 139, 116, .2); height:100vh; width:30%; overflow-y:auto; padding:20px 35px}

.exam-container{padding-left:40px; padding-right:40px}

.exam-instructions{margin-top:50px; margin-bottom:50px;}
.exam-instructions h4{}
.exam-instructions ul{}
.exam-instructions ul li{display:flex; margin-bottom:15px;}
.exam-instructions ul li span{background-color:#DD0000; width:24px; height:24px; border-radius:50%; margin-right:10px; display:flex; align-items:center; justify-content:center; color:#fff; font-size:12px; font-weight:bold;}
.exam-instructions ul li div{flex:1; color:rgba(0,0,0,.6); font-size:14px;}

.MuiCard-root.exam-info-card{box-shadow:0px 15px 20px rgba(0,0,0,.05); margin-bottom:15px; border-radius:15px;}
.MuiCard-root.exam-info-card .MuiCardContent-root{display:flex; flex-direction:column;}
.exam-info-card h4{font-size:13px; margin-top:0; margin-bottom:5px;}
.exam-info-card .time{color:#A33220; margin-bottom:15px; font-size:13px;}
.exam-info-card .text{color:#808080; font-size:13px;}

.MuiCard-root.exam-info-card:before{content:attr(num); position:absolute; left:-23px; top:16px; color:rgba(0,0,0,.8);}
.MuiCard-root.exam-info-card.option-card{margin-left:15px; position:relative; overflow:visible; cursor:pointer; transition:.2s all ease-in-out}
.MuiCard-root.exam-info-card.option-card:hover{box-shadow:0 10px 15px rgba(0,0,0,.15);}
.MuiCard-root.exam-info-card.option-card h4{margin-bottom:0}
.MuiCard-root.exam-info-card.option-card .MuiCardContent-root:last-child{padding-bottom:16px}

.MuiCard-root.exam-info-card.option-card.selected{position:relative}
.MuiCard-root.exam-info-card.option-card.selected:after{content:''; position:absolute; width:10px; height:100%; top:0; left:0; display:block; z-index:99; background-color:#d00; border-radius:10px 0;}

.sticky-actions{background-color:#222222; position:fixed; bottom:0; padding:20px; border-radius:15px 15px 0 0; width:400px; left:50%; margin-left:-200px}


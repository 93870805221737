
/* @import url('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,300;0,400;0,600;0,700;1,800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=swap');

html{height:100%}
/* body{font-family:'Poppins', sans-serif; font-size:13px; height:100%} */
#root{height:100%}
/* #root > div{height:100%} */

.main-grid{height:100%;}

.MuiTypography-root{font-family:'Poppins', sans-serif !important; font-size:15px;}

.flex{display:flex; justify-content:flex-start; align-items:center;}
.flex-center{display:flex; align-items:center; justify-content:center;}
.space-between{display:flex; justify-content:space-between; align-items:center; width:100%;}
.vertical{display:flex; flex-direction:column;}
.horizontal{display:flex; align-items:center; justify-content:center;}
.end,
.align-end{display:flex; justify-content:flex-end; align-items:center;}
.align-start{display:flex; justify-content:flex-start; align-items:center;}
.align-top{display:flex; justify-content:space-between; align-items:flex-start;}
.align-center{display:flex; justify-content:space-between; align-items:center;}
.baseline{align-items:baseline;}

.hidden{display:none !important}

.c-p{color:rgb(93, 106, 184) !important}
.c-r{color:rgb(199, 38, 16) !important;}
.c-g{color:#1FB425 !important;}

.fw-n{font-weight:normal !important;}
.fw-5{font-weight:500 !important;}
.fw-6{font-weight:600 !important;}
.fw-7{font-weight:700 !important;}

.cg-3{color:rgba(0,0,0,.3)}
.cg-4{color:rgba(0,0,0,.4)}
.cg-5{color:rgba(0,0,0,.5)}
.cg-6{color:rgba(0,0,0,.6)}
.cg-7{color:rgba(0,0,0,.7)}
.cg-8{color:rgba(0,0,0,.8)}
.cg-9{color:rgba(0,0,0,.9)}

.ml-3{margin-left:3px !important;}
.ml-5{margin-left:5px !important;}
.ml-10{margin-left:10px !important;}
.ml-15{margin-left:15px !important;}
.ml-20{margin-left:20px !important;}

.mr-3{margin-right:3px !important;}
.mr-5{margin-right:5px !important;}
.mr-10{margin-right:10px !important;}
.mr-15{margin-right:15px !important;}
.mr-20{margin-right:20px !important;}

.mb-0{margin:0 !important}
.mb-5{margin-bottom:5px !important;}
.mb-10{margin-bottom:10px !important;}
.mb-20{margin-bottom:20px !important;}
.mb-30{margin-bottom:30px !important;}

.mt-0{margin-top:0 !important}
.mt-5{margin-top:5px !important;}
.mt-10{margin-top:10px !important;}
.mt-20{margin-top:20px !important;}
.mt-30{margin-top:30px !important;}
.mt-50{margin-top:50px !important;}

.fs-10{font-size:10px !important; letter-spacing:.5px;}
.fs-11{font-size:11px !important}
.fs-12{font-size:12px !important}
.fs-14{font-size:14px !important}
.fs-16{font-size:16px !important}
.fs-18{font-size:18px !important}
.fs-20{font-size:20px !important}

.p-0{padding:0 !important}

.ellipsis{overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}

.loader{width:100%; height:100%; position:absolute; top:0; left:0; z-index:999;}
.loader .MuiCircularProgress-root{}

.action-sm{display:flex; justify-content:flex-end;}
.action-sm button{padding:3px 5px; font-size:.7rem; margin-left:5px;}
.action-sm button.btn-main{}

button.MuiButton-containedPrimary{background-color:#DD0000; color:rgba(255,255,255, 0.9); text-transform:uppercase; box-shadow:none; border-radius:5px; padding:7px 25px}
button.MuiButton-containedPrimary:hover{box-shadow:none; background-color:#aa0303}

button.MuiButton-outlinedPrimary{color:#DD0000; border:1px solid #DD0000}
button.MuiButton-outlinedPrimary:hover{background-color:rgb(221, 0, 0, .05); border:1px solid #DD0000}

button.MuiButton-containedSecondary{background-color:#1FB425; border-radius:25px; box-shadow:none; font-weight:600; padding:7px 25px}
button.MuiButton-containedSecondary:hover{background-color:#0ba510;}

.header{padding:5px 0;}
.header-block{display:flex; align-items:center; justify-content:space-between; padding:0;}

.header-block .third-block .links{display:flex; justify-content:flex-start; align-items:center}
.MuiButton-text.profile-menu{background-color:rgb(221, 0, 0, .1); padding:12px 20px; border-radius:0}
.MuiButton-text.profile-menu .MuiAvatar-root{background-color:#FF928E;}

.header-block .third-block .links .link{margin-left:5px}
.header-block .third-block .links .link .MuiSvgIcon-root{color:#8E8E8E; font-size:30px}

.header-block .navigation .nav-link{display:flex; position:relative;}
.header-block .navigation .nav-link.has-child .MuiList-root{background-color:#fff; position:absolute; top:50px; width:100%; z-index:9999;}
.header-block .navigation .nav-link.has-child:hover .MuiList-root{display:block !important;}
.navigation-submenu{background-color:#fff; padding:10px; box-shadow:0 15px 11px rgba(0, 0, 0, .1); border-radius:0 0 5px 5px}
.navigation-submenu li{padding:0}
.navigation-submenu a{font-size:14px; color:rgba(0, 0, 0, 0.8); display:block; width:100%; padding:6px;}

.fix-col{display:flex; flex-direction:column; height:100%; align-items:stretch}
.fix-col.first{margin-right:20px;}
.fix-col.center{margin:0 10px 0 20px;}
.fix-col.last{margin-left:20px;}
.fix-col .header-bar{width:auto; margin-right:15px; margin-bottom:0}
.fix-col .fix-col-list-container{flex:1}
.fix-col .fix-col-list{padding-right:15px}
.fix-col .fix-col-list .task-card{margin-bottom:25px; padding-bottom:25px;}
.fix-col .fix-col-list .task-card:hover{border-bottom:1px solid rgba(0, 0, 0, 0.2)}

.fix-col .fix-col-list .task-card:first-child{margin-top:35px}
.fix-col .fix-col-list .task-card:last-child{border-bottom:1px solid transparent}
.fix-col .fix-col-list .task-card:last-child:hover{border-bottom:1px solid transparent}

.scroll-track{opacity:.1 !important;}

.inline-error{color:rgb(199, 38, 16); background-color:rgba(199, 38, 16, .05); padding:5px 10px; text-align:center; margin:5px 0; border-radius:5px}

.no-data-container{width:100%; align-items:center; justify-content:center; display:flex;}
.no-data{width:400px; align-items:center; display:flex; justify-content:center; flex-direction:column;}
.no-data img{width:100%}
.no-data .MuiSvgIcon-root{font-size:100px; color:rgba(0,0,0,.1)}
.no-data p{font-weight:bold; font-size:30px; color:rgba(0,0,0,.2); text-transform:capitalize;}

.MuiPopover-paper{border-radius:10px !important; box-shadow:0 5px 20px rgba(0,0,0,.15) !important}
.MuiPopover-paper .MuiMenu-list{min-width:200px; padding:0px 0}
.MuiPopover-paper .MuiMenu-list .MuiMenuItem-root{padding-top:10px; padding-bottom:10px; border-bottom:1px solid rgba(0, 0, 0, 0.08)}
.MuiPopover-paper .MuiMenu-list .MuiMenuItem-root:last-child{border-bottom:0 none}
.MuiSelect-nativeInput{font-size:13px !important; font-family:'Poppins' !important;}
.MuiFormLabel-root{font-size:13px !important; font-family:'Poppins' !important;}

.MuiMenu-paper{border-radius:10px !important; box-shadow:0 5px 10px rgba(0,0,0,.15) !important}

.MuiPopover-root{background-color:rgba(255,255,255,.2);}

.MuiChip-sizeSmall{height:auto !important; padding:1px 2px 1px 2px !important; text-transform:uppercase !important; font-size:11px !important; font-family:'Poppins', sans-serif !important;}

.MuiChip-root.round{border-radius:100%; width:30px; height:30px;}
.MuiChip-sizeSmall.round{width:21px !important; height:21px !important;}
.MuiChip-root.round .MuiChip-label{padding:0}

.MuiButton-label{text-transform:capitalize; font-family:'Poppins', sans-serif; font-size:15px;}

.has-hoverable-action:hover .hoverable-action{opacity:1}
.hoverable-action{opacity:0; transition:.2s all ease-in-out}

.sidebar-col.MuiGrid-item{max-width:50px; width:50px; background-color:rgba(0, 0, 0, 0.6);}
.main-col.MuiGrid-item{max-width:calc(100% - 50px); width:calc(100% - 50px); flex-basis:calc(100% - 50px)}

.sidebar{background-color:rgba(0, 0, 0, 0.05); height:100%; border-right:1px solid rgba(0, 0, 0, 0.05)}
.sidebar-container{padding:7px 0px 20px 0px; display:flex; flex-direction:column; position:fixed; width:50px}

.sidebar-container .logo{display:block; align-self:flex-start; margin-left:7px;}
.sidebar-container .logo .logo-block{width:33px; overflow:hidden}
.sidebar-container .logo img{width:75px; display:block;}

.main-nav-container{margin-top:6px; border-top:1px solid rgba(255,255,255,.25)}
.main-nav-container a{text-decoration:none; color:rgba(0,0,0,.8); transition:.2s all ease-in-out;}
.main-nav-container a:hover .MuiListItem-button{background-color:rgba(255,255,255, .1);}
.main-nav-container .MuiListItem-gutters{padding:5px 5px 5px 8px; margin:5px; width:auto; border-radius:3px;}
.main-nav-container .MuiListItemIcon-root{min-width:15px; color:rgba(255,255,255,.7);}
.main-nav-container .MuiListItemIcon-root .MuiSvgIcon-root{}
.main-nav-container .MuiListItemText-root{padding-left:0; margin:0; display:none}
.main-nav-container a.active{}
.main-nav-container a.active .MuiButtonBase-root{background-color:rgba(255,255,255,.2); border-radius:5px}
.main-nav-container .MuiListItemText-root .MuiTypography-displayBlock{font-size:12px !important; font-weight:600;}
.main-nav-container a.active .MuiButtonBase-root .MuiListItemIcon-root{color:rgba(255,255,255, 0.8)}

.main{background-color:#fff; height:100%; display:flex; flex-direction:column;}
.main-wrapper{padding:0 40px 0px 40px; height:100%; display:flex; flex-direction:column;}
.main-content-block{flex:1}

.MuiPaper-root.wd-accordion{box-shadow:none; margin-bottom:10px; background-color:transparent;}
.MuiAccordion-root.wd-accordion::before{background:none}
.MuiAccordion-root.wd-accordion.Mui-expanded{margin:0}
.wd-accordion .MuiAccordionDetails-root{padding:0}

.main-title-block{display:flex; justify-content:space-between; align-items:center; margin-top:10px; margin-bottom:10px}
.main-title-block h1{margin:0; font-size:22px;}

/* .MuiTypography-body1{font-family:'Poppins', sans-serif !important; font-size:14px !important} */

.vertical-tabs{display:flex;}
.vertical-tabs .MuiTabs-root{width:110px; border-right:1px solid #e8eaf6}
.vertical-tabs .MuiTabs-root button.MuiTab-root{min-width:110px}
.vertical-tabs .vertical-tabs-content{flex:1; min-height:600px;}
.vertical-tabs .tab-content{padding:0 40px 40px 40px}
.tab-title{margin-top:0;}

.table-fields-list{margin-left:90px}

.header-bar{border-top:1px solid rgba(0, 0, 0, 0.1); border-bottom:1px solid rgba(0, 0, 0, 0.1); padding-top:6px; padding-bottom:6px; display:flex; align-items:center; justify-content:flex-start; margin-bottom:25px; width:100%}
.header-bar .heading{margin:0; font-size:12px; color:rgba(0,0,0,.9); text-transform:uppercase; font-weight:600; display:flex; align-items:center; text-decoration:none}
.breadcrumb{list-style-type:none; margin:0; padding:0; display:flex; justify-content:flex-start; align-items:center;}
.breadcrumb li{position:relative; margin-right:30px; padding-left:30px;}
.breadcrumb li:first-child{padding-left:0;}
.breadcrumb li:last-child:after{display:none}
.breadcrumb li:after{font-family:'Material Icons'; content:'\e315'; display:block; position:absolute; z-index:999; top:0; right:-42px; color:rgba(0,0,0,.3); font-size:20px;}

.exam-review{width:70%; box-shadow:0 0 10px rgba(0,0,0,.1); padding:30px; border-radius:20px; margin:50px auto; background-color:#fff; display:flex; align-items:flex-start; justify-content:center;}
.exam-review-progress{width:200px; padding-left:50px; padding-right:50px;}
.exam-review-content{display:flex; flex-direction:column; align-items:flex-start;}
.exam-review-content h5{margin-top:0; font-size:25px; font-weight:600; margin-bottom:20px}
.exam-review-content strong{font-weight:500}
.exam-review-content p{}
.exam-review-content .action-sm{margin-top:50px}
.exam-review-content .action-sm .btn{margin-right:40px}

.project-view .main-title-block h1{margin-bottom:0}

.subheader-toolbar{border-bottom:1px solid rgba(0,0,0,.1); padding-bottom:5px; margin-bottom:10px;}
.subheader-toolbar .subheader-title{font-size:12px}
.subheader-toolbar .subheader-title strong{}

.editable{min-height:300px; margin-top:0px; padding:10px; margin-bottom:20px; outline:none; font-family:'Poppins', sans-serif; font-size:.9rem; color:rgba(0,0,0,.7); border-bottom:1px solid rgba(0,0,0,.1); border-left:1px solid rgba(0,0,0,.1); border-right:1px solid rgba(0,0,0,.1); position:relative}
.editable:empty:before{content:attr(placeholder); position:absolute; color:rgba(0,0,0,.5); background-color:transparent; font-family:'Poppins', sans-serif;}
.editable:focus::before{color:rgba(0,0,0,.1)}

.editable img{max-width:80%; max-height:300px;}

.editable-sm{min-height:200px; max-height:250px; overflow-y:auto; margin-bottom:10px}

.editor-toolbar{list-style-type:none; padding:0; margin:15px 0 0px 0; border:1px solid rgba(0,0,0,.1); display:flex; flex-direction:row; justify-content:flex-start; align-items:flex-start; border-radius:4px 4px 0 0}
.editor-toolbar li{display:flex; align-items:center; justify-content:center; margin:5px 2px}
.editor-toolbar li button{border:0 none; padding:0; width:25px; height:25px; border-radius:4px; background-color:transparent; cursor:pointer; transition:.2s all ease-in-out; display:flex; justify-content:center; align-items:center;}
.editor-toolbar li button:hover{background-color:rgba(0,0,0,.05);}
.editor-toolbar li button.active{background-color:rgba(0,0,0,.08);}
.editor-toolbar li button svg{width:20px; color:rgba(0,0,0,.7)}
.editor-toolbar .color-button{width:15px; height:15px; cursor:pointer; border-radius:50%;}

.task-card{margin-bottom:50px; border-bottom:1px solid rgba(0, 0, 0, 0.2); transition:.2s all ease-in-out; width:100%;}
.task-card:hover{border-bottom:1px solid rgba(0,0,0,.8);}
.task-card > a{margin:0 0 10px 0; font-size:15px; font-weight:600; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; color:#000; text-decoration:none;}
.task-card h2{margin:0; font-size:15px; font-weight:600; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}
.task-card .MuiCardHeader-root{padding:10px}
.task-card .MuiTypography-h5{font-size:1.1rem; font-family:'Poppins', sans-serif; line-height:1.1; margin-bottom:5px; color:#000}
.task-card .MuiCardHeader-subheader{font-size:12px; color:rgba(0,0,0,.7)}
.task-card-header-row{display:flex; justify-content:space-between; align-items:center; padding-top:5px;}

.task-description{font-size:12px; color:rgba(0,0,0,.7); font-weight:400;}
.task-description p{margin:0 0 20px 0}
.task-description img{max-width:100%}
.task-description a{color:rgba(16, 36, 216, 0.9)}
.task-description *{overflow-wrap:break-word !important; white-space:normal !important}

.task-card .MuiCardContent-root{padding:10px}

.task-card.progressing{border-top:3px solid #299cbe}
.task-card.progressing.paused{border-top:3px solid #be2974}

.task-card .sub-level-list-container{background-color:rgba(0,0,0,.01); padding:5px 15px; border-radius:5px; margin-top:10px}

.task-card .subtasks .wd-accordion{padding:8px 8px; background-color:rgba(0,0,0,.02); border-radius:5px; border:1px solid transparent}
.task-card .subtasks .wd-accordion.Mui-expanded{margin-bottom:10px; background-color:rgba(0,0,0,.0); border:1px solid rgba(0, 0, 0, 0.05)}
.task-card .subtasks .wd-accordion .task-card{margin-bottom:0px; padding-bottom:0; margin-top:5px}
.task-card .subtasks .wd-accordion .task-card .task-description{margin-bottom:10px}

.seperator{border-top:1px solid rgba(0, 0, 0, 0.1); padding-top:10px; margin-top:10px;}

.MuiPopover-paper{border-radius:6px}
.popover-confirm{padding:25px; position:relative}
.popover-confirm p{}
.popover-confirm .popover-actions{text-align:right}

.popover-confirm.large{width:800px}

.progress-bar-container{width:100%; height:10px; display:flex; background-color:#d1d9f1; border-radius:10px}
.progress-bar{height:10px;}
.progress-bar:last-child{border-top-right-radius:10px; border-bottom-right-radius:10px;}
.progress-bar:first-child{border-top-left-radius:10px; border-bottom-left-radius:10px;}
.progress-bar.start{background-color:#1cb86a;}
.progress-bar.pause{background-color: #ee3d31;}
.progress-bar.submit{background-color: #2e89d3;}
.progress-bar.skipped{background-color: #2b2b2b;}

.builder-block{position:relative}
/* .builder-block:before{content:attr(data-title); display:block; position:absolute; top:-11px; left:10px; font-size:10px; text-transform:uppercase; background-color:#fff; padding:0px 5px; letter-spacing:.5px; border:1px solid rgba(0, 0, 0, 0.1)} */

.container-block{border:1px solid #fff; background-color:#e9f0f1; padding:25px 10px 5px 10px; flex:1; flex-direction:column; display:flex; margin-bottom:15px; box-shadow:0 5px 5px rgba(0,0,0,.1)}
.container-wrapper{display:flex; justify-content:flex-start; flex-direction:row;}

.container-grid-wrapper{display:flex; justify-content:flex-start; flex-direction:column;}
.container-items{border:1px solid rgba(0, 0, 0, 0.05); background-color:#ffffff; padding:10px 10px; flex:1;}
.container-items:last-child{margin-right:0}

.wrapper-block{border:1px solid #fff; background-color:rgba(186, 211, 225, .7); padding:25px 10px 5px 10px; margin-bottom:15px; flex:1; box-shadow:0 5px 5px rgba(0,0,0,.1); border-radius:2px;}
.wrapper-wrapper{display:flex; flex-direction:column;}

.item-block{border:1px solid #bad3e1; background-color:#ffffff; padding:25px 10px 5px 10px; flex:1;}

.form-block{border:1px solid #e1caba; background-color:#fff5f5; padding:25px 10px 5px 10px; flex:1;}

.builder-main-toolbar{opacity:0}
.builder-block:hover > .builder-main-toolbar{opacity:1;}
.builder-main-toolbar .MuiSvgIcon-root{font-size:15px;}

.MuiMenuItem-root.builder-menu-item{font-size:12px; font-weight:300;}

.builder-block-menu{position:absolute; top:-10px; left:15px; background-color:#fff; padding:0px 5px; border:1px solid rgba(0,0,0,.1); display:flex; justify-content:space-between; align-items:center; border-radius:3px}
.builder-block-menu label{font-size:10px; text-transform:uppercase; letter-spacing:1px;}
.builder-block-menu .bbm-actions{}
.builder-block-menu .bbm-action{}
.builder-block-menu .bbm-action .MuiSvgIcon-root{font-size:14px;}

.dropdown-popup-container{}
.dropdown-popup-container .MuiPopover-paper{box-shadow:none; background-color:transparent;}
.dropdown-popup{position:relative; width:300px; border-radius:5px; padding:25px; background-color:#fff; box-shadow:0 5px 5px rgba(0,0,0,.2); margin:50px 10px 10px 10px;}
.dropdown-popup:before{content:''}

.components-list{}
.components-list .MuiListItem-gutters{padding-top:4px; padding-bottom:4px; border-bottom:1px solid rgba(0,0,0,.1)}
.components-list .MuiListItem-gutters:last-child{border-bottom:0 none;}
.components-list .MuiTypography-body1{text-transform:uppercase; font-weight:bold; color:rgba(0,0,0,.4); font-size:12px;}
.components-list-row{border-bottom:1px solid rgba(0,0,0,.1); padding:10px 5px 10px 5px; background-color:rgba(0,0,0,.01);}
.components-list-row button{text-transform:capitalize; font-size:11px; font-weight:600; border:0 none; padding:6px 15px; cursor:pointer; background-color:transparent; transition:.2s all ease-in-out; border-radius:5px; min-width:20px;}
.components-list-row button:hover{background-color:rgba(0, 0, 0, 0.05);}

.sub-level-list-container{}
.sub-level-list-container .heading{margin:0; text-transform:uppercase; font-size:11px;}
.sub-level-list{list-style-type:none; margin:0; padding:5px;}
.sub-level-list li{}

.data-list{list-style-type:none; margin:0; padding:0; width:100%}
.data-list li{padding-top:10px; padding-bottom:5px; border-bottom:1px solid rgba(0, 0, 0, 0.1); display:flex; justify-content:space-between; align-items:center}
.data-list li:first-child{padding-top:0}
.data-list li:last-child{border-bottom:0 none}
.data-list li label{font-weight:500; color:rgba(0, 0, 0, 0.6); font-size:12px;}
.data-list li .value{color:rgba(0, 0, 0, 0.9); font-weight:500; font-size:12px}

.data-list.numbered{list-style-type:decimal-leading-zero; padding-left:25px; font-size:12px; color:rgba(0,0,0,.6);}
.data-list.numbered > li{display:revert}

.article-view{width:100%}
.article-view .header{}
.article-view .header h1{margin:0; font-weight:600; color:rgba(0, 0, 0, 0.9); font-size:20px;}
.article-view .header h2{margin:0; font-weight:600; color:rgba(0, 0, 0, 0.7); font-size:14px;}

.label-block{width:50px; height:50px; display:flex; align-items:center; justify-content:center; flex-direction:column; background-color:rgba(0,0,0,.08); border-radius:50%}
.label-block span{text-transform:uppercase; font-size:10px; color:rgba(0,0,0,.8); line-height:1; margin-top:5px;}
.label-block strong{font-weight:bold; font-size:20px; line-height:1; color:rgba(0, 0, 0, 0.7)}

.red-dot{background-color:#dc004e; top:-3px; right:-3px; width:10px; height:10px; border-radius:20px; position:absolute; transition:.2s all ease-in-out; animation:1s infinite alternate pinch}

.content-list{}
.content-list-item{display:flex; justify-content:flex-start; align-items:flex-start; position:relative; margin-bottom:35px; padding-bottom:35px; border-bottom:1px solid rgba(0,0,0,.1);}
.content-list-details{padding:10px 20px 20px 20px; border:1px solid rgba(0,0,0,.1); border-radius:5px; background-color:#fff;}
.content-list-header{}

.content-list-container{flex:1; margin-left:30px}

.content-list-item.item-hidden{}
.content-list-item.item-hidden .content-list-details{}
.content-list-item.item-expanded{}

.content-list-item .label-block{position:relative;}
.content-list-item .label-block:after{content:''; display:block; width:30px; height:1px; background-color:rgba(0,0,0,.15); position:absolute; top:50%; right:-30px;}

.drawer-container{height:100%; display:flex; flex-direction:column; width:900px; background-color:#fff}
.drawer-container.sm{width:600px}
.drawer-heading{padding:15px 30px; display:flex; justify-content:space-between; border-bottom:1px solid rgba(0, 0, 0, 0.1)}
.drawer-heading h2{color:rgba(0, 0, 0, 0.8); font-size:20px; font-weight:bold; margin:0}
.drawer-body{flex:1; overflow-y:auto;}
.drawer-content{padding:30px}
.drawer-footer{padding:15px 30px; display:flex; justify-content:space-between; background-color:#fff; border-top:1px solid rgba(0,0,0,.2)}

@keyframes pinch{from{transform:scale(.4); opacity:.3}to{transform:scale(1); opacity:1}}

/*      Start       */

.main-content{}
.main-content-full{}

.main-content-title{margin-bottom:25px}
.main-content-title h1{font-weight:400; font-size:30px; color:#003965; margin:0}
.main-content-title .page-subtitle{color:#6E939C; font-weight:normal}

.homepage .main-content{padding:0; background:#fff}
.loginpage.main-content,
.signuppage.main-content{padding:0;}

.d-flex{display:flex !important;}
.rev-row{display:flex; flex-direction:row-reverse;}
.rev-column{display:flex; flex-direction:column-reverse;}
.justify-center{display:flex; align-items:center; justify-content:space-between;}
.justify-content-between{justify-content:space-between !important;}
.justify-content-center{justify-content:center !important;}
.justify-content-start{justify-content:flex-start !important;}
.justify-content-end{justify-content:flex-end !important;}

.text-center{text-align:center;}
.text-left{text-align:left;}
.text-right{text-align:right;}

img,
.img-fluid{max-width:100%;}

a{color:#236eaf; text-decoration:none;}
a:hover{color:inherit;}

/*      Buttons Start        */
.btn,
.MuiButton-root.btn{background-color:#fff; border:1px solid #ddd; border-radius:5px; color:#ddd; cursor:pointer; display:flex; font-size:15px; font-weight:500; justify-content:space-between; padding:6px 25px;}
.btn-success,
.MuiButton-root.btn-success{background-color:#1FB425; border-color:#1FB425; color:#fff; align-items:center; justify-content:center;}
.btn-success-blur,
.MuiButton-root.btn-success-blur{background-color:rgba(45,219,52,0.18); border:1px hidden rgba(45,219,52,0.18); color:#1FB425;}
.btn-red,
.MuiButton-root.btn-danger{background-color:#DD0000; border-color:#DD0000; color:#fff;}
.btn-blk{background-color:#000; border-color:#000; color:#fff;}

.MuiButton-root.btn-red-light{background-color:rgb(226, 106, 39, .2); border-color:rgb(226, 106, 39, .3); color:rgba(226, 106, 39, 1); align-items:center; justify-content:center;}
.MuiButton-root.btn-red-light:hover{background-color:rgb(226, 106, 39, 1); color:#fff}

.MuiButton-root.btn-success:hover,
.MuiButton-root.btn-success-blur:hover{background-color:#fff; border:1px solid #1FB425; color:#1FB425;}
.btn-red:hover,
.MuiButton-root.btn-danger:hover{background-color:#fff; border-color:#DD0000; color:#DD0000;}
.btn-blk:hover{background-color:#fff; border-color:#000; color:#000;}

.link-btn{background-color:#DD0000; border-color:#DD0000; color:#fff; padding:8px 20px; border-radius:5px; font-weight:bold}

.payment-block{width:400px; box-shadow:0 5px 15px rgba(0, 0, 0, .1); margin:0 auto; padding:20px}
/*      Buttons End        */

.hide{display:none;}
ul{margin-bottom:0px; padding-left:0px; list-style-type:none;}
.pointer{cursor:pointer;}

/*      Icons Start     */
.ico{background-image:url('../images/icons.png'); background-repeat: no-repeat; display:inline-block;}
.ico.facebook{background-position:-12px -8px; height:18px; width:10px;}
.ico.twitter{background-position:-43px -8px; height:18px; width:20px;}
.ico.youtube{background-position:-79px -9px; height:18px; width:24px;}
.ico.instagram{background-position:-119px -9px; height:18px; width:18px;}

ul.social-icons li a:hover .ico.facebook{background-position:-12px -37px;}
ul.social-icons li a:hover .ico.twitter{background-position:-43px -37px;}
ul.social-icons li a:hover .ico.youtube{background-position:-79px -38px;}
ul.social-icons li a:hover .ico.instagram{background-position:-119px -38px;}
/*      Icons End     */


body{color:#000; margin:0; padding:0; font-family:'Poppins', sans-serif, tahoma, arial; font-size:15px; min-height:100%; overflow-x:hidden;}
.mat-ico{font-family:'Material Icons'; font-weight:normal; font-style:normal; font-size:24px; display:inline-block; line-height:1; text-transform:none; letter-spacing:normal; word-wrap:normal; white-space:nowrap; direction:ltr; -webkit-font-smoothing:antialiased; text-rendering:optimizeLegibility; -moz-osx-font-smoothing:grayscale; font-feature-settings:'liga';}

.MuiContainer-maxWidthLg{max-width:1280px !important;}

.header-alerts{position:fixed; z-index:999; top:25px; right:50px; width:400px}

/*      Header Start       */
.menu{display:flex; margin-top:0px; position:relative;}
.nav-link{padding:0px;}
.menu a{color:#666; display:block; font-size:15px; padding:6px 20px; text-decoration:none;}
/* .menu .sub-menu{background-color:#eaeaea; display:none; left:100%; padding-top:20px; position:absolute; top:0px; width:100%;}
.menu .sub-menu .sub-menu-2{background-color:#fff; display:none; left:100%; padding-top:20px; position:absolute; top:0px; width:100%;} */
.menu > li.nav-link > a{color:#666;}
.menu > li.nav-link > a:hover{color:#DD0000;}
.menu > li:hover .sub-menu{display:block;}
/* .sub-menu > li > a:hover{background-color:#fff;}

.sub-menu > li:hover .sub-menu-2{display:block;}
.sub-menu-2 > li > a:hover{background-color:#f8f8f8;} */

.header .links .btn:not(:first-child){margin-left:20px;}
/*      Header End     */

/*      Footer Start    */
.footer{background:linear-gradient(to right, #212121 60%, #000 40%); color:#E5E5E5;}
.footer a{color:#939393;}
.footer a:hover{color:#DD0000;}
.footer .heading{color:#E5E5E5; font-weight:700; margin-bottom:20px; margin-top:45px;}
.footer .heading.txt-white{color:#fff;}
.footer-block{display:flex; justify-content:space-between; padding-bottom:40px;}
.footer-block .block{width:25%;}
.footer-block .block:last-child{width:32%;}
.footer-block .footer-logo{background-color:#F2F2F2; border-radius:0 0 8px 8px; display:inline-block; margin-bottom:30px; padding:10px 15px;}
.footer-block .email{margin-bottom:50px;}
.footer-block .list-inline{margin-top:0px;}
.footer-block .list-inline li{margin-bottom:20px;}
.footer-block .social-icons li{margin-right:15px;}

.footer-block .address-text span{color:#4D4D4D; font-weight:300;}
.footer-block .gmap{border-radius:10px; height:100px; margin-top:20px; overflow:hidden; width:100%;}
.footer-block .gmap iframe{height:100%; width:100%;}
/*      Footer End    */

.center-align-page{background-color:rgba(0,0,0,.07); width:100%}
.center-align-page .align-center{display:flex; align-items:center; justify-content:flex-start; flex-direction:column; padding-top:30px}
.center-align-page .block-wrapper{width:55%; display:flex; flex-direction:column; padding:50px; background-color:#fff; box-shadow:0 0 5px rgba(0, 0, 0, .1); border-radius:10px; margin-bottom:50px}

.center-align-page .block-wrapper.sm{width:350px}

.thanks-message{display:flex; flex-direction:column; align-items:center;}
.thanks-message h2{font-family:Georgia, 'Times New Roman'; font-weight:normal; color:#000; font-size:30px; margin-bottom:20px;}
.thanks-message h3{text-align:center; color:#396; font-weight:bold; font-size:20px; line-height:1.3;}
.thanks-message p{margin:0; text-align:center;}
.thanks-message strong{}
.thanks-message hr{width:100%; opacity:.3; margin-top:30px; margin-bottom:20px;}
.thanks-message h2.done{color:#DD0000; font-size:50px; font-weight:bold}

/*      Homepage Start      */
/* .banner-slider .banners-block{background-repeat:no-repeat; background-position:center center; color:#fff; height:689px; overflow:hidden; width:100%;} */
.banner-slider .banners-block{background-repeat:no-repeat; background-position:50%; color:#fff; height:550px; overflow:hidden; width:100%;}

.banner-slider .bg-block{height:100%; overflow:hidden; width:100%;}
.banner-slider .MuiContainer-root{display:flex; flex-direction:row; height:100%; justify-content:space-between; margin-top:-41%;}
.banner-slider .block{max-width:496px; position:relative; /*margin-top:-66px; position:absolute; top:110px;*/}
.banner-slider .head-text{font-size:70px; font-weight:800; line-height:74px; margin-bottom:10px;}
.banner-slider .subhead-text{color:#FFCE00; font-size:22px; font-weight:600; line-height:30px; margin-bottom:10px;}
.banner-slider .description{font-size:17px; margin-bottom:35px; width:90%;}
.banner-slider .description .MuiTypography-root{font-family:'Poppins', sans-serif !important; font-size:17px/* !important*/;}
.banner-slider .description .MuiListItem-root{padding:0px;}
.banner-slider .description .MuiListItemIcon-root{color:#FFCE00; margin-right:12px; min-width:20px;}
.banner-slider .links a{border:0px none; box-shadow:0 0 20px rgba(0,0,0,0.2); color:#000; display:inline-block; font-size:17px; font-weight:700; padding:10px 30px;}
.banner-slider .links a span{display:block;}
.banner-slider .links a .text{letter-spacing:1.2px; text-transform:uppercase;}
.banner-slider .links a .price-text{color:#DD0000; font-weight:400;}

.banner-below-section{box-shadow:0 0 80px rgba(0,0,0,0.25); display:flex; overflow:hidden;}
.banner-below-section > div{box-shadow:0 0 80px rgba(0, 0, 0, .15); border-radius:0px; width:33.34%;}
.banner-below-section .MuiCardContent-root{align-items:center; display:flex; justify-content:center;}
.banner-below-section .text{color:#4D4D4D; display:flex; flex-direction:column; font-size:24px; font-weight:600; margin-left:20px;}

.section{padding:40px 0;}

.core-test-section{padding:80px 0;}
.core-test-section .head-text{font-size:25px; font-weight:600; margin-bottom:10px; text-align:center;}
.core-test-section .sub-text{color:#666; font-size:20px; line-height:34px; margin-bottom:80px; text-align:center;}
.core-test-section .main-section{display:flex; justify-content: space-between;}
.core-test-section .main-section .img-block{max-width:100%; width:520px;}
.core-test-section .main-section .mid-block{align-self:center; background-color:#000; border-radius:50%; color:#fff; display:flex; flex-direction:column; height:99px; justify-content:center; text-align:center; width:99px;}
.core-test-section .main-section .mid-block i.mat-ico{font-size:36px;}
.core-test-section .test-blocks{display:flex; flex-wrap:wrap; justify-content:space-between; max-width:100%; width:465px;}
.core-test-section .test-blocks .block{background-position:center center; background-repeat:no-repeat; background-size:cover; box-shadow:0 0 20px rgba(0,0,0,0.2); display:flex; flex-direction:column; height:calc(208px - 70px); justify-content:space-between; margin-top:20px; padding:40px 15px 30px; text-align:center; width:calc(223px - 30px);}
.core-test-section .test-blocks .block.yellow{background-image:url(../images/core-test-bg-yellow.png); color:#4D4D4D;}
.core-test-section .test-blocks .block.red{background-image:url(../images/core-test-bg-red.png); color:#fff;}

.core-test-section .test-blocks .block:nth-child(1),
.core-test-section .test-blocks .block:nth-child(2){margin-top:0px;}
.core-test-section .test-blocks .block:hover{box-shadow:0 0 20px rgba(0,0,0,0.4);}

.core-test-section .test-blocks .block .bottom{font-size:18px; font-weight:700;}

.section-heading{color:#4D4D4D; font-size:40px; font-weight:700; line-height:1; margin-bottom:25px; padding-bottom:25px; position:relative; text-align:center; text-transform:uppercase;}
.section-heading::after{background-color:#DD0000; content:''; display:block; position:absolute; bottom:0px; height:2px; left:calc(50% - 35px); width:70px;}
.section-subheading{color:#666; margin:0 auto 50px; text-align:center; width:60%;}

.certification-section{display:none; padding-top:0px;}
.certification-section .certifications{display:flex; justify-content:space-between;}
.certification-section .certifications .card{border-radius:10px; box-shadow:0 0 20px rgba(0,0,0,0.1); width:260px;}
.certification-section .certifications .card:hover{box-shadow:0 0 20px rgba(0,0,0,0.25)}
.certification-section .certifications .card > div{padding:0px;}
.certification-section .certifications .MuiCardMedia-root{background-size:inherit; height:140px;}
.certification-section .certifications .card.red .MuiCardMedia-root{background-color:#FFE3E3;}
.certification-section .certifications .card.yellow .MuiCardMedia-root{background-color:#FFF7D7;}
.certification-section .certifications .card .text{color:#4D4D4D; font-weight:600; padding:20px;}

.faqs-section .MuiExpansionPanel-root{box-shadow:none; padding:0px;}
.faqs-section .MuiExpansionPanel-root:not(:last-child){margin-bottom:20px;}
.faqs-section .MuiExpansionPanel-root::before,
.faqs-section .MuiAccordion-root::before{display:none;}
.faqs-section .MuiExpansionPanel-root.Mui-expanded{border:0px none; margin-bottom:30px;}
.faqs-section .MuiExpansionPanel-root.Mui-expanded .MuiButtonBase-root{border-radius:5px;}
.faqs-section .MuiButtonBase-root{min-height:64px; padding:0 20px;}
.faqs-section .MuiButtonBase-root.Mui-expanded{background-color:#FFE3E3; color:#DD0000;}
.faqs-section .MuiAccordionDetails-root{flex-direction:column;}
.faqs-section .MuiAccordionDetails-root .MuiTypography-root p:not(:last-child){margin-bottom:10px;}
.faqs-section .MuiPaper-rounded{margin:16px 0;}

.faqs-section .MuiExpansionPanelSummary-root{border:1px solid #939393; border-radius:5px;}
.faqs-section .MuiExpansionPanelSummary-root.Mui-expanded{border-color:#FFE3E3;}
.faqs-section .MuiExpansionPanelSummary-content{margin:20px 0;}
.faqs-section .MuiExpansionPanelSummary-content p{font-size:20px; font-weight:500;}
.faqs-section .MuiExpansionPanelDetails-root{padding:20px;}
.faqs-section .MuiExpansionPanelDetails-root p{color:#666;}

body .MuiPaper-rounded{border-radius:5px;}
.why-germany-section .main-section{display:flex; justify-content: space-between;}
.why-germany-section .main-section .img-block{max-width:100%; width:540px;}
.why-germany-section .card-blocks{display:flex; flex-wrap:wrap; justify-content:space-between; max-width:100%; padding:20px 0; width:540px;}
.why-germany-section .card-blocks .card{background-position:center center; background-repeat:no-repeat; background-size:cover; box-shadow:0 0 20px rgba(0,0,0,0.2); display:flex; flex-direction:column; justify-content:space-between; margin-top:20px; text-align:center; width:260px;}
.why-germany-section .card-blocks .card:hover{box-shadow:0 0 20px rgba(0,0,0,0.4);}

.why-germany-section .card-blocks .card:nth-child(1),
.why-germany-section .card-blocks .card:nth-child(2){margin-top:0px;}
.why-germany-section .card-blocks .card:last-child:nth-child(2n + 1){width:100%;}

.why-germany-section .MuiCardContent-root{padding:0px;}
.why-germany-section .card .num{background-color:#DD0000; color:#fff; font-size:24px; font-weight:700; padding:5px 20px;}
.why-germany-section .card .text{color:#666; padding:20px;}
.why-germany-section .card .text .title{color:#000; font-size:18px; font-weight:600; margin-bottom:5px;}

.testimonials-section{background:url('../images/testimonials-bg.png') no-repeat scroll center center; background-size:cover; padding:60px 0;}
.testimonials-section .section-heading{margin-bottom:60px; padding-bottom:70px;}
.testimonials-section .testimonials{display:flex; justify-content:space-between;}
.testimonials-section .testimonials .single{width:500px;}
.testimonials-section .testimonials .single .text{background-color:#fff; border-radius:10px; color:#4D4D4D; font-weight:500; margin-bottom:40px; min-height:160px; padding:40px 50px; position:relative;}
.testimonials-section .testimonials .single .text::after{border-top:25px solid #fff; border-left:18px solid transparent; border-right:18px solid transparent; content:''; display:block; position:absolute; bottom:-25px; left:50px;}
.testimonials-section .testimonials .single .name{font-size:25px; font-weight:600;}
.testimonials-section .testimonials .single .designation{color:#DD0000; font-size:18px; font-weight:300; font-style:italic;}
.testimonials-section .testimonials .single .name,
.testimonials-section .testimonials .single .designation{padding-left:45px;}
/*      Homepage End        */

.MuiInputLabel-formControl{z-index:1}

.form-control.select .MuiInputLabel-formControl{left:25px}
.form-control.select .MuiInputLabel-formControl.MuiInputLabel-animated{left:17px}

/*      Login & Signup Start        */
.MuiInputBase-input{font-size:15px !important; padding:20px !important;}
.MuiInputLabel-outlined:not(.Mui-focused){padding-left:10px;}

.MuiOutlinedInput-inputMarginDense{padding:12px !important; font-size:14px !important}
.MuiInput-inputMarginDense{padding:12px !important; font-size:14px !important}

.MuiFilledInput-root{transition: background-color 200ms cubic-bezier(red) 0ms}
div[class^=PrivateRadioButtonIcon] .MuiSvgIcon-root,
.MuiCheckbox-colorSecondary.Mui-checked,
.MuiRadio-colorPrimary.Mui-checked{align-self:flex-start; color:#DD0000 !important;}

.login-main-page{min-height:600px;}
.homepage,
.login-main-page,
.signup-main-page{background-color:#F9F9F9; padding-bottom:0; padding-top:0;}

.login-page .half-container,
.signup-page .half-container{display:flex; flex-direction:column; justify-content:flex-start; padding:80px; width:calc(50% - 100px);}

.patterned-bg{background-color:#dc0100; position:relative; overflow:hidden}
.patterned-bg:after{content:''; display:block; position:absolute; top:-60px; right:-30%; width:100%; height:150%; background-color:rgba(0,0,0,.15); transform:rotate(20deg); z-index:0}

.patterned-bg .text-block{z-index:9; background-image:url(../images/signup-bg.png); width:405px; height:454px}

.login-page .heading,
.signup-page .heading{color:#fff; font-size:50px; font-weight:700; margin-bottom:30px;}
.login-page .subheading,
.signup-page .subheading{color:#fff; font-size:30px; margin-bottom:20px;}
.login-page .text,
.signup-page .text{color:#F3F4FA;}
.auth-block .section-heading{font-size:30px; font-weight:700; text-align:left;}
.auth-block .section-heading::after{display:none;}

.signup-page .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{padding:0 !important}
.signup-page .form-control.select .MuiFormControl-root .MuiInputLabel-formControl.MuiInputLabel-animated{left:0}

.login-page{min-height:700px}

.login-main-page .form,
.signup-main-page .form{display:flex; flex-direction:column;}
.login-main-page .form-control,
.signup-main-page .form-control{margin-bottom:20px;}
.login-main-page .MuiOutlinedInput-input,
.signup-main-page .MuiOutlinedInput-input{background-color:#F3F4FA;}

.remember-forgot{align-items:center; color:#455A64; flex-direction:row !important; justify-content:space-between;}
.remember-forgot a{color:#455A64;}

.login-main-page .form .btn-red,
.signup-main-page .form .btn-red{background-color:#DD0000; border-color:#DD0000; border-radius:10px; color:#fff; font-weight:500; margin-bottom:35px; padding:15px;}
.login-main-page .form .btn-red .MuiButton-label,
.signup-main-page .form .btn-red .MuiButton-label{text-transform:uppercase;}
.red-txt{color:#DD0000;}
.green-txt{color:2;}
.yellow-txt{color:#FFCE00;}

.login-main-page .d-flex,
.signup-main-page .d-flex{flex-direction:row; justify-content:space-between;}
.signup-main-page .d-flex > div.right{width:48%;}

.signup-main-page .d-flex.radio-buttons{align-items:center; justify-content:flex-start;}
.signup-main-page .d-flex.radio-buttons > div{margin-bottom:20px; width:auto;}
.signup-main-page .d-flex.radio-buttons > div:not(:last-child){margin-right:10px;}

.email-verified .main-content{padding-top:80px; text-align:center;}
.new-proposal-wrap{text-align:center;background:#fff;padding:35px 0;margin:30px 0;}
.new-proposal-wrap h3{color:#414141;font-size:25px;line-height:38px;margin:0;}
.new-proposal-wrap p{color:#AAA7A7;font-size:14px;line-height:21px;margin:20px 0 15px;}
.new-proposal-wrap .btn{display:inline-block;}
/*      Login & Signup End        */

.price-container{margin-bottom:10px; position:relative}
.price-box{display:flex; align-self:center; align-items:baseline;}
.price-box .regular-price{}
.price-box .regular-price.with-special{}

.price-box .regular-price .price-currency{color:#DD0000; font-size:20px;}
.price-box .regular-price .price-amount{color:#D00; font-weight:600; font-size:20px; margin-left:2px}

.price-box .regular-price.with-special .price-amount{color:rgba(0,0,0,.5); position:relative; margin:0 7px}
.price-box .regular-price.with-special .price-amount:after{position:absolute; left:-10%; top:50%; width:120%; height:1px; background-color:#666; display:block; content:''}

.price-box .special-price{}
.price-box .special-price .price-amount{color:#D00; font-weight:600; font-size:20px; margin-left:2px}

.price-discount{background-color:#D00; width:40px; height:40px; display:flex; align-items:center; justify-content:center; flex-direction:column; border-radius:50%; right:0; top:0px; position:absolute;}
.price-discount strong{color:#fff; font-size:15px; font-weight:600; line-height:1;}
.price-discount span{color:#fff; line-height:1; text-transform:uppercase; font-size:11px;}

.price-saving{font-size:12px;}

/*      Account Start     */
.account{padding-bottom:50px;}
.account .banner-block{display:flex; flex-direction:column; justify-content:center; margin-bottom:50px; min-height:250px; overflow:hidden; position:relative;}
.account .banner-block .banner-img{min-height:100%; min-width:100%; position:absolute; top:0px; z-index:1;}
.account .banner-block .container{color:#fff; line-height:1.2; position:relative; z-index:2;}
.account .banner-block .container .heading{color:#fff; font-weight:bold; margin:0px;}
.account .banner-block .container .txt{font-size:43px; font-weight:700;}

.account .welcome-txt{color:#DD0000; font-size:23px; font-weight:500; margin-bottom:20px;}
.cards-listing .card{border-radius:14px; color:#535353; box-shadow:0px 5px 15px rgba(0,0,0,0.06);}
.cards-listing .card:not(:last-child){margin-bottom:20px;}
.cards-listing .list-heading,
.cards-listing .MuiCardHeader-root{background-color:#ffeae6; border-radius:10px}
.cards-listing .list-heading,
.cards-listing .MuiCardHeader-root .MuiTypography-root{color:#000; font-size:19px !important; font-weight:600 !important;}
.cards-listing .MuiCardContent-root > .d-flex{justify-content:space-between;}
.cards-listing .MuiCardContent-root > .d-flex > div:not(:last-child){margin-right:20px;}
.cards-listing .img-block{background-color:#F8F5F5; height:134px; align-self:center; display:flex; justify-content:center; flex-direction:column; text-align:center; align-items:center; width:134px;}
.cards-listing .MuiCardContent-root:last-child{padding-bottom:0}
.cards-listing .details-block{width:40%;}
.cards-listing .inc-block{text-align:center; width:calc(90% - 40% - 190px - 134px);}
.cards-listing .buttons-block{display:flex; flex-direction:column; justify-content:center; width:190px;}
.cards-listing .buttons-block.sm{width:100px; align-items:center; justify-content:flex-start;}
.cards-listing .details-block .title{font-size:17px; font-weight:600;}
.cards-listing .details-block .subtitle{font-size:15px; margin-bottom:5px;}
.cards-listing .details-block .text{font-size:14px;}
.cards-listing .inc-block .text{font-size:15px; font-weight:600; margin-bottom:10px; text-align:center;}
.cards-listing .inc-block .MuiButton-outlined{border:0px none;}
.cards-listing .inc-block .MuiFormControl-root{width:60px;}
.cards-listing .inc-block .MuiOutlinedInput-root{border-radius:0px;}
.cards-listing .inc-block .MuiInputBase-input{background-color:#F8F5F5; border-color:#ddd; border-radius:0px; padding:10px 15px !important; text-align:center;}
.cards-listing .buttons-block .MuiButton-root{font-weight:600; padding:12px 25px;}
.cards-listing .buttons-block .MuiButton-root:not(:last-child){margin-bottom:15px;}
.cards-listing .buttons-block .MuiButton-root .MuiButton-label{font-size:16px; justify-content:center;}

.cards-listing .buttons-block.sm .title{color:#A2A2A2; font-size:15px; margin-bottom:15px;}
.cards-listing .buttons-block.sm .MuiButton-root{padding:6px;}
.cards-listing .buttons-block.sm .MuiButton-root .MuiButton-label{font-size:12px;}

.cards-listing .list-heading{border-radius:3px; margin-bottom:20px; padding:16px;}
.cards-listing ul{display:flex; flex-wrap:wrap;}
.cards-listing ul li{padding:0px; width:48%; display:flex; align-items:flex-start; justify-content:flex-start;}
.cards-listing ul .MuiListItemIcon-root{align-self:flex-start; color:#39DB12; margin-top:8px; min-width:20px;}
.cards-listing ul .MuiSvgIcon-root{font-size:16px;}
.cards-listing ul .MuiTypography-root{font-size:13px !important;}

.account.test-results .cards-listing .details-block .title{margin-bottom:10px;}
.account.test-results .cards-listing .details-block .text{font-size:13px;}

.cards-listing .results-block{display:flex; justify-content:space-evenly; text-align:center; width:calc(90% - 40% - 100px - 134px);}
.cards-listing .results-block .blocks-section .block{background-color:rgba(248,245,245,0.64); padding:10px; text-align:center; width:102px;}
.cards-listing .results-block .blocks-section .block:not(:last-child){margin-bottom:15px;}
.cards-listing .results-block .blocks-section .block .text{color:#A2A2A2;}
.cards-listing .results-block .blocks-section .block .num{font-weight:700;}

.cards-listing .results-block .results-section .title{color:#DD0000;}
.cards-listing .results-block .results-section > div{color:#1FB425; font-weight:700;}
.cards-listing .results-block .results-section > div:not(:last-child){margin-bottom:20px;}

.modal{display:flex; flex-direction:column; justify-content:center;}
.modal .MuiDialogTitle-root{padding:0}
.modal .modal-block{background-color:#fff; border-radius:10px; margin:0 auto; overflow:hidden; width:1280px;}
.modal.md .modal-block{width:990px;}

.modal .modal-title{background-color:#272727; color:#fff; font-size:21px; font-weight:700; margin:0px; padding:10px 20px; position:relative;}
.modal .btn-close{font-weight:700; padding:5px 12px; position:absolute; right:8px; top:7px;}
.modal .description.d-flex{display:flex; justify-content:space-between;}
.modal .left-block,
.modal .right-block{padding:30px;}
.modal .left-block{display:flex; flex-direction:column; width:60%;}
.modal .left-block > div{display:flex; justify-content:space-between;}
.modal .right-block{background-color:rgba(253,184,171,0.18); width:40%;}

.modal .left-block .heading,
.modal .right-block .heading{color:#808080; font-size:14px; font-weight:600; margin-bottom:10px;}
.modal .right-block .heading{color:#1FB425;}

.modal .left-block .section{line-height:2; padding:0px;}
.modal .left-block .section:not(:last-child){margin-right:15px;}
.modal .left-block .section.first .text{color:#000;}
.modal .left-block .section:not(.first) .text{color:#1FB425;}
.modal .text{font-size:13px;}

.modal .left-block .section{width:25%;}
.modal .left-block .section:nth-child(1){width:45%;}

.progress{display:flex; justify-content:space-between; line-height:0.8;}
.MuiLinearProgress-root{height:8px !important;}
.MuiLinearProgress-colorPrimary{align-self:center; background-color:#E0D5D3 !important; border-radius:3px; width:90%;}
.MuiLinearProgress-barColorPrimary{background-color:#1FB425 !important;}

.modal .right-block .section{margin-bottom:1.4px; padding:0px;}
.modal .right-block .num{font-size:13px;}

/*      Account End     */


/*      TestAs Page Start       */
.banner-image{min-height:100%; min-width:100%; max-width:unset;}
/* .test-as.main .banner-slider .MuiContainer-root{flex-direction:row; justify-content:space-between;} */
/*.test-as.main */.banner-slider .MuiContainer-root .block:first-child{display:flex; flex-direction:column; justify-content:center; margin-top:0; max-width:45%;}
form .title{font-size:32px; font-weight:700; line-height:1.2;}
form .subtitle{font-size:32px; line-height:1.2; margin-bottom:30px; text-transform:uppercase;}
form .form-control{margin-bottom:12px;}
.MuiOutlinedInput-multiline{padding:0px !important;}
form .MuiButtonBase-root.btn-red{background-color:#DD0000; border-color:#DD0000; border-radius:10px; color:#fff; font-weight:500; padding:15px; text-align:center;}
form .MuiButtonBase-root.btn-red .MuiButton-label{justify-content:center; text-transform:uppercase;}
form .MuiTypography-root{font-size:inherit;}

.banner-slider .banners-block.half-form{overflow:inherit;}

.banners-block.half-form .MuiContainer-root .block:last-child{align-self:flex-start; max-width:50%; position:relative; width:50%;}
.banners-block.half-form.plus .MuiContainer-root .block:last-child{max-width:55%; width:55%;}
/* .banners-block.half-form .block form{background-color:#fff; border-radius:10px; box-shadow:0 -3px 20px rgba(0,0,0,0.21); color:#212121; display:flex; flex-direction:column; left:20px; padding:30px; position:absolute; width:calc(100% - 100px); top:100px;} */
.banners-block.half-form .block form{background-color:#fff; border-radius:10px; box-shadow:0 -3px 20px rgb(0 0 0 / 21%); color:#212121; display:flex; flex-direction:column; left:20px; padding:30px; position:absolute; width:calc(100% - 100px); top:50px;}
.banners-block form .input{background-color:#F3F4FA;}

.banner-below-container{margin-top:12px;}
.highlights-block{display:flex; justify-content:space-between; max-width:50%;}
.highlights-block .text{background-color:#FFCE00; border-radius:10px; color:#343434; display:flex; flex-direction:column; padding:20px 45px; text-align:center;}
.highlights-block .text span{font-size:20px; font-weight:500; line-height:1.1;}
.highlights-block .text span:first-child{color:#fff; font-size:50px; font-weight:700; text-shadow:0 3px 6px rgba(0,0,0,0.12);}
.banner-below-container .note{font-size:11px; font-weight:500; margin-top:5px; text-align:right; width:50%;}

.study-planning-section{padding-bottom:80px;}
.study-planning-section .main-section{justify-content:space-between;}
.study-planning-section .main-section .text-block{width:46%;}
.study-planning-section .main-section .img-block{width:50%;}
.study-planning-section .section-heading{font-weight:500; line-height:56px; text-align:left; text-transform:inherit;}
.study-planning-section .section-heading span{color:#DD0000; font-weight:700;}
.study-planning-section .section-heading::after{left:0px;}
.study-planning-section .text{color:#666; font-size:20px; line-height:34px;}
.study-planning-section .btn{display:inline-block; margin-top:20px; text-transform:uppercase;}

.why-germany{height:475px; padding:0px;}
.why-germany .section-bg{background-image:url(../images/why-germany-bg.jpg); background-repeat:no-repeat; background-position:center center; background-size:cover; color:#fff; height:475px; overflow:hidden; padding-bottom:0px; position:relative;} width:100%;
/* .why-germany .bg-block{width:100%; position:absolute; bottom:0} */ .why-germany .bg-block{height:100%; width:100%;}
.why-germany .bg-block img{height:100%; max-width:unset;}
.why-germany .MuiContainer-root{align-items:center; display:flex; height:100%; justify-content:space-between; position:relative;}
.why-germany .MuiContainer-root > .block{height:calc(100% - 60px); position:relative; margin-top:60px; width:100%;}
.why-germany .section-heading,
.why-germany .section-subheading{color:#fff;}
.why-germany .section-heading::after{background-color:#FFCE00;}
.why-germany .section-subheading{left:0px; font-weight:600; max-width:250px; position:absolute; top:0px; text-align:left;}
.why-germany .main-section{bottom:0px; display:flex; justify-content:space-between; position:absolute;}
.why-germany .bars{display:flex; justify-content:space-between; width:100%;}
.why-germany .bars .block{color:#fff; display:flex; flex-direction:column; justify-content:flex-end; width:20%;}
.why-germany .bars .block .num{color:#FFCE00; font-size:30px; font-weight:600;}
.why-germany .bars .block .title{font-size:30px; font-weight:600; line-height:1.2; margin-bottom:5px;}
.why-germany .bars .block .text{font-size:13px; font-weight:500; margin-bottom:5px;}
.why-germany .bars .block .bar{background-image:linear-gradient(to top, rgba(255,0,0,0), #FFCE00);}

.program-details .banner-slider + .section{padding-top:80px;}
.program-details .main-section{display:flex; flex-direction:column;}
.program-details .main-section > div:not(:last-child){margin-bottom:30px;}
.program-details .main-section > div{display:flex; justify-content:space-between;}
.program-details .top-block{background-color:#343434; color:#fff; padding:40px;}
.program-details .top-block .block{text-align:center; width:22%;}
.program-details .top-block .block .title{color:#FFCE00; font-size:15px; font-weight:500; margin:10px 0; text-transform:uppercase;}
.program-details .top-block .block .details{color:#fff; font-size:17px; font-weight:700;}
.program-details .top-block .block .details span{font-size:11px; font-weight:600;}
.program-details .mid-block .block{color:#666; font-size:15px; width:65%;}
.program-details .mid-block .block:first-child{width:30%;}
.program-details .mid-block .title{color:#DD0000; font-weight:500; margin-bottom:10px; text-transform:uppercase;}
.program-details .steps .step{margin-bottom:5px;}
.program-details .note-block{align-items:center; background-color:#FFF9E0; color:#4D4D4D; display:flex; font-size:15px; font-weight:600; padding:10px 15px; width:calc(80% - 30px);}
.program-details .note-block img{margin-right:10px;}
.program-details .bottom-block .btn{flex-direction:column; padding:15px 25px; text-align:center; width:calc(18% - 50px);}

.courses-listing{display:flex; flex-wrap:wrap; justify-content:space-between;}
.courses-listing li{align-items:flex-start; margin-bottom:10px; padding:0px 15px; position:relative; width:30%;}
.courses-listing li::before{content:''; display:block; position:absolute; left:0px; top:10px; height:5px; width:5px; background-color:#000; border-radius:50%; overflow:hidden;}
.courses-listing li .MuiListItemText-root{margin:0px;}
.courses-listing li .MuiTypography-root{color:#666; font-weight:500; font-size:inherit;}


/*      About TestAs Start      */
.banner-slider .head-text.sm{font-size:47px; line-height:55px;}
.form .MuiFormGroup-root.d-flex{flex-direction:row; justify-content:space-between;}
.form .MuiFormGroup-root.d-flex > div{width:48%;}

.test-modules-highlight-listing,
.test-modules-listing{display:flex; flex-wrap:wrap; justify-content:space-between; font-size:17px;}
.test-modules-highlight-listing{background-color:#FFCE00; color:#343434;}
.test-modules-highlight-listing li{align-items:flex-start; padding:0px 15px; position:relative; width:23%;}
.test-modules-highlight-listing li .MuiTypography-root{font-weight:600; font-size:inherit;}
.test-modules-listing li{align-items:flex-start; margin-bottom:10px; padding:0px 15px; position:relative; width:23%;}
.test-modules-listing li .MuiListItemText-root{margin:0px;}
.test-modules-listing li .MuiTypography-root{color:#666; font-weight:500; font-size:inherit;}

.test-modules .MuiPaper-root,
.registration-schedule .MuiPaper-root{box-shadow:none; border-radius:0px;}
.registration-schedule .MuiTableBody-root .MuiTableRow-root{border-bottom:1px solid #ddd;}
.test-modules-table .MuiTableHead-root,
.registration-schedule .MuiTableHead-root{background-color:#FFCE00; color:#343434;}
.test-modules-table .MuiTableCell-root,
.registration-schedule .MuiTableCell-root{border-bottom:0px none; color:#343434; font-size:17px; }
.registration-schedule .MuiTableCell-root{font-weight:700;}
.test-modules-table .MuiTableBody-root .MuiTableCell-root,
.registration-schedule .MuiTableBody-root .MuiTableCell-root{color:#666; font-weight:500; padding:10px 16px;}

.ref{color:#4D4D4D; font-size:14px; width:100%;}
.mt-2{margin-top:20px;}
.text-right{text-align:right;}

/*     GermanLanguage Start      */
.german-language .banner-slider + .section{padding-top:80px;}
.german-lang-section .main-section{justify-content:space-between;}
.german-lang-section .main-section .block{width:35%;}
.german-lang-section .main-section .block:last-child{width:60%;}
.german-lang-section .main-section .block .text{color:#343434; font-size:20px; font-weight:500;}
.options-listing{display:flex; flex-wrap:wrap; justify-content:space-between;}

.options-listing li{color:#343434;}
.options-listing li .MuiTypography-root{font-size:15px;}
.options-listing li .MuiListItemIcon-root{color:#343434; align-self:flex-start; margin:4px 0; min-width:30px;}

.options-listing.half li{align-items:flex-start; margin-bottom:10px; padding:0px 30px; position:relative; width:calc(48% - 30px);}
.options-listing.half li::before,
.options-listing.bullets li::before{content:''; display:block; position:absolute; left:10px; top:10px; height:5px; width:5px; background-color:#000; border-radius:50%; overflow:hidden;}
.options-listing.bullets li{align-items:flex-start; margin-bottom:10px; padding:0px 30px; position:relative;}
.options-listing.bullets li::before{top:13px;}
.options-listing.half li .MuiTypography-root{font-weight:600;}


/*     SubjectChoices Start      */
.subject-choices{color:#666;}
p{color:#343434; line-height:28px;}
.note{font-size:15px; font-weight:600; margin-top:5px;}

/*      DuringStudyGermany Start        */
.during-study .banner-slider + .section{padding-top:80px;}
.list-heading{color:#343434; font-size:20px; font-weight:600;}

/*      AfterStudy Start        */
.after-study .banner-slider + .section{padding-top:80px;}
.note.yellow-bg{background-color:#FFCE00; color:#000; font-weight:400; padding:10px 15px;}
.salary-section .section-heading{color:#DD0000; font-size:25px; font-weight:600; padding-bottom:0px; text-align:left; text-transform:capitalize;}
.salary-section .section-heading::after{display:none;}

.salary-section .MuiPaper-root{box-shadow:none; border-radius:0px;}
.salaries-table .MuiTableHead-root{color:#343434;}
.salaries-table .MuiTableCell-root{border-bottom:0px none; color:#343434; font-size:15px; font-weight:700;}
.salaries-table .MuiTableBody-root .MuiTableCell-root{color:#666; font-size:17px; font-weight:500; padding:10px 16px;}

.salaries-table .MuiTableBody-root .MuiTableRow-root:nth-child(2n+1){background-color: #F5F5F5;}

/*      PreviousProcedure Start     */
.previous-procedure .section{padding-bottom:0px;}
.previous-procedure .section-heading{color:#DD0000; font-size:25px; font-weight:600; padding-bottom:0px; text-align:left; text-transform:capitalize;}
.previous-procedure .section-heading::after{display:none;}

/*      BlockedAccount Start     */
.blocked-account .list-heading{font-size:15px;}

/*      AboutStudyFeeds Start       */
/* .section.studyfeeds-section{padding-top:80px;} */
.about-studyfeeds .banner-slider + .section{padding-top:80px;}
.studyfeeds-section .d-flex{justify-content:space-between;}
.studyfeeds-section .section-heading{color:#DD0000; font-size:25px; font-weight:600; line-height:35px; padding-bottom:0px; text-align:left; text-transform:capitalize;}
.studyfeeds-section .section-heading::after{display:none;}

.studyfeeds-section .text-block{width:44%;}
.studyfeeds-section .img-block{width:48%;}

/*      TestAs Page End       */

/*      Contact Page Start      */
.contact-us .banner-slider + .section{padding-top:180px;}
.contact-details-section .main-section{justify-content:space-between;}
.contact-details-section .main-section > div{width:48%;}
.contact-details-section .map-block iframe{height:500px; width:96%;}

.contact-details-section .section-heading{color:#DD0000; font-size:25px; font-weight:600; margin-top:50px; padding-bottom:0px; text-align:left; text-transform:capitalize;}
.contact-details-section .section-heading::after{display:none;}
.contact-details-section .social-icons li{margin-right:30px;}
/*      Contact Page End      */

.cc-form-container{margin:20px 0}
.cc-form{padding:20px; box-shadow:0 0 5px rgba(0,0,0,.2); background-color:#fff; border-radius:5px; margin-bottom:20px;}
.cc-form-actions{display:flex; justify-content:flex-end;}

@media only screen and (max-width: 768px){
.mobile-nav{width:100%}
.mobile-nav .menu{width:100%; display:flex; flex-direction:column;}
.mobile-nav .menu .nav-link{padding:10px 15px}
.mobile-nav .menu .nav-link a{color:#666; font-size:15px; padding:0; border-bottom:1px solid rgba(0,0,0,.1);}
.mobile-nav .menu .nav-link button{color:#666; font-size:15px; padding:0; border-bottom:1px solid rgba(0,0,0,.1); width:100%; text-align:left; display:block}

.mobile-nav .menu .nav-link ul{padding:10px;}
.mobile-nav .menu .nav-link ul li{}
.mobile-nav .menu .nav-link ul li a{padding:5px; font-size:12px}

.header-block .third-block{}
.header-block .third-block .links{}
.header-block .third-block .links ul{margin:0; display:flex}
.header-block .third-block .links ul li{}

.banner-slider .banners-block{height:auto}
.banner-slider .bg-block{height:auto}

.banner-slider .MuiContainer-root{flex-direction:column; margin-top:-180%}
.banner-slider .block{margin-top:0; width:50%}
.banner-slider .head-text,
.banner-slider .head-text.sm{font-size:18px; line-height:22px;}

.banner-slider .subhead-text{font-size:13px; line-height:15px;}
.banner-slider .description{display:none}
.banner-slider .links{margin-top:50px}

.banners-block.half-form .MuiContainer-root .block:last-child,
.banners-block.half-form.plus .MuiContainer-root .block:last-child{max-width:100%; width:100%;}

.banners-block.half-form.plus .MuiContainer-root{flex-direction:column;}
.form .MuiFormGroup-root.d-flex > div{width:100%;}

.banner-below-section{margin:20px 15px; box-shadow:none}
.banner-below-section .MuiCardContent-root{flex-direction:column;}
.banner-below-section > div{}
.banner-below-section .MuiPaper-root:nth-child(2n){margin:0 15px}
.banner-below-section .text{font-size:13px; text-align:center; margin:0}
.banner-below-section > div{box-shadow:none;}

.section-heading{font-size:32px;}
.section-heading::after{bottom:-4px;}
.section-subheading{margin-bottom:20px; width:90%;}

.core-test-section{padding:40px 0}
.core-test-section .head-text{font-size:18px;}
.core-test-section .sub-text{font-size:12px; line-height:18px; margin-bottom:30px;}

.core-test-section .main-section{flex-direction:column;}
.core-test-section .main-section .mid-block{margin:10px 0; width:50px; height:50px;}

.core-test-section .test-blocks{width:100%}
.core-test-section .test-blocks .block{width:calc(50% - 40px)}

.certification-section .certifications{flex-wrap:wrap;}
.certification-section .certifications .card{width:calc(50% - 15px); margin-bottom:30px}

.why-germany-section .main-section{flex-direction:column;}
.why-germany-section .card-blocks{width:100%; justify-content:center;}
.why-germany-section .card-blocks .card{width:30%; margin-top:0; margin-bottom:20px}
.why-germany-section .card-blocks .card:last-child:nth-child(2n+1){width:30%}

.why-germany-section .card .text{padding:20px 10px; font-size:12px;}
.why-germany-section .card .text .title{font-size:13px;}
.MuiCardContent-root:last-child{padding-bottom:0}

.why-germany-section .card-blocks .card:nth-child(2){margin-left:5%; margin-right:5%}
.why-germany-section .card-blocks .card:nth-child(4){margin-right:5%}

.testimonials-section .section-heading{margin-bottom:25px; padding-bottom:25px;}
.testimonials-section .testimonials{flex-direction: column;}
.testimonials-section .testimonials .single{width:100%}
.testimonials-section .testimonials .single:nth-child(2){margin-top:50px}
.testimonials-section .testimonials .single .text{font-size:12px; min-height:50px; padding:25px}
.testimonials-section .testimonials .single .name{font-size:15px;}
.testimonials-section .testimonials .single .designation{font-size:13px;}

.footer{background:#212121;}
.footer-block{flex-direction:column;}
.footer-block .block{width:100%}
.footer-block .block:last-child{width:100%}
.footer-block .email{margin-bottom:25px}
.footer .heading{margin-top:20px}

.login-page{flex-direction:column;}

.login-main-page .d-flex, .signup-main-page .d-flex{flex-direction:column;}
.login-page .half-container.left,
.signup-page .half-container.left{display:none}

.login-page .half-container,
.signup-page .half-container{width:calc(100% - 80px); padding:40px}
.signup-main-page .d-flex > div.right{width:calc(100% - 80px); padding:40px}

.signup-main-page .d-flex.radio-buttons{align-items:flex-start;}

.main-content .banner-slider .MuiContainer-root{flex-direction:column;}
.main-content .banner-slider .MuiContainer-root .block:first-child{width:100%; max-width:100%; margin-top:15px;}
.banners-block.half-form .MuiContainer-root .block:last-child{max-width:100%; width:100%}
.banners-block.half-form .block form{position:relative; width:calc(100% - 60px); top:auto; left:auto;}
form .title{font-size:16px;}
form .subtitle{font-size:17px;}

.banner-below-container{}

.highlights-block{width:100%; max-width:100%}
.highlights-block .text{width:calc(48% - 50px); padding:20px 25px}

.banner-below-container{margin-top:40px}
.highlights-block .text span:first-child{font-size:30px; margin-bottom:10px;}
.highlights-block .text span{font-size:14px;}

.study-planning-section{margin-top:20px}

.study-planning-section .main-section{flex-direction:column;}
.study-planning-section .main-section .img-block{order:1; width:100%}
.study-planning-section .main-section .text-block{order:2; width:100%; margin-top:15px;}
.study-planning-section .section-heading{font-size:20px; line-height:23px;}
.study-planning-section .text{font-size:14px; line-height:23px;}

.why-germany .MuiContainer-root{align-items:flex-start;}
.why-germany .section-heading,
.why-germany .section-subheading{margin-bottom:10px; padding-bottom:5px}

.why-germany .MuiContainer-root > .block{margin-top:25px; display:flex; flex-direction:column; height:100%; position:relative}
.why-germany .section-subheading{position:relative; width:100%; max-width:100%; margin:0;}

.why-germany .main-section{bottom:45px;}
.why-germany .bars{width:100%}
.why-germany .bars .block{width:18%}
.why-germany .bars .block .num{font-size:20px}
.why-germany .bars .block .title{font-size:12px;}
.why-germany .bars .block .text{font-size:11px; line-height:12px;}

.program-details .section-heading{font-size:32px;}
.program-details .section-subheading{width:100%}

.program-details .main-section > div.top-block{display:flex; flex-direction:row; flex-wrap:wrap; padding:0; background-color:transparent;}
.program-details .top-block .block{text-align:center; width:calc(49% - 40px); padding:20px; background-color:#343434; margin-bottom:2%}
.program-details .top-block .block .details{font-size:12px;}
.program-details .top-block .block .title{font-weight:bold; font-size:14px;}

.program-details .main-section > div.mid-block{flex-direction:column; width:100%}
.program-details .mid-block .block:first-child{width:100%; margin-bottom:15px;}
.program-details .mid-block .block{width:100%}
.program-details .mid-block .title{font-weight:bold; font-size:14px;}
.program-details .mid-block .block{font-size:13px;}

.program-details .main-section > div.bottom-block{flex-direction:column; align-items:center}
.program-details .note-block{width:auto}
.program-details .bottom-block .btn{width:calc(70% - 50px); margin-top:15px}

.courses-offered .section-heading{font-size:30px;}

.courses-listing li{width:50%}
.courses-listing li .MuiTypography-root{font-size:12px !important}


.test-modules .MuiTable-root{min-width:650px;}
.test-modules-table .MuiTableCell-root{font-size:14px;}
.ref{font-size:12px;}

p{font-size:13px; line-height:22px;}
.MuiTypography-root,
.options-listing li .MuiTypography-root{font-size:12px;}
.options-listing.half li::before,
.options-listing.bullets li::before{top:11px;}
.options-listing li,
.options-listing.bullets li{margin-bottom:5px; padding:0px 8px;}
.options-listing.bullets li{padding:0px 24px;}

.german-language .banner-slider + .section,
.during-study .banner-slider + .section,
.about-studyfeeds .banner-slider + .section,
.after-study .banner-slider + .section{padding-top:40px;}

.studyfeeds-section .section-heading{font-size:20px; line-height:26px; margin-bottom:10px;}
.studyfeeds-section .d-flex{flex-direction:column;}
.studyfeeds-section .text-block,
.studyfeeds-section .img-block{width:100%;}
.list-heading{font-size:16px;}

.german-lang-section .main-section{flex-direction:column;}
.german-lang-section .main-section .block,
.german-lang-section .main-section .block:last-child{margin-bottom:20px; width:100%;}
.german-lang-section .main-section .block .text{font-size:16px;}

.previous-procedure .section-heading{font-size:22px; margin-bottom:10px;}
.note{font-size:12px;}

/*      Contact Page Start      */
.contact-us .banner-slider + .section{padding-top:0px;}
.contact-details-section .main-section{flex-direction:column;}
.contact-details-section .main-section > div{width:100%;}
.contact-details-section .main-section .map-block{margin-top:40px;}
.contact-details-section .map-block iframe{height:250px;}
/*      Contact Page End      */
}

@media only screen and (min-width: 992px) and (max-width: 1280px){
.banner-slider .MuiContainer-root{margin-top:-43%;}
}

@media only screen and (min-width: 1367px) and (max-width: 1440px){
.banner-slider .MuiContainer-root{margin-top:-38.2%;}
}

@media only screen and (min-width: 1441px) and (max-width: 1920px){
.banner-slider .MuiContainer-root{margin-top:-28.5%;}
}